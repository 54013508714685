<template>
  <div class="page">
    <div class="page-header">下载中心</div>
    <div class="search">
      <el-input v-model="name" placeholder="请输入操作人员名称" size="medium" clearable></el-input>
      <el-select v-model="type" class="m-l-32" placeholder="请选择导出模块" clearable>
        <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value" size="medium">
        </el-option>
      </el-select>
      <el-select v-model="status" class="m-l-32" placeholder="请选择导出状态" clearable>
        <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value" size="medium">
        </el-option>
      </el-select>
      <span class="m-l-32 m-r-16">操作时间</span>
      <el-date-picker v-model="time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期" size="medium" end-placeholder="结束日期">
      </el-date-picker>
      <div class="search-btn">
        <div class="query" @click="getList">查询</div>
        <div class="reset m-l-16" @click="reset">重置</div>
      </div>
    </div>
    <el-card shadow="never" class="m-t-16 card">
      <div class="batch-del m-b-16 " @click="delList">
        批量删除
      </div>
      <!-- 表格 -->
      <el-table ref="multipleTable" :data="tableData" @selection-change="handleSelectionChange" stripe :header-cell-style="{
          'text-align': 'center',
          background: '#F6F7FA',
          color: '#333',
        }" :cell-style="{ 'text-align': 'center' }" style="
          width: 100%;
          text-align: center;
          border: 0.0625rem solid #e4e6ec;
          border-bottom: none;
        ">
        <el-table-column type="selection" width="100">
        </el-table-column>
        <el-table-column label="任务名称" show-overflow-tooltip>
          <template slot-scope="scope">
            <div @click="downloadFile(scope.row)" :style="scope.row.status==1?{color:'#67c23a'}:{color:'#D8D8D8'}" class="cursor texthidden">
              {{ scope.row.fileName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="moduleName" label="导出模块">
        </el-table-column>
        <el-table-column prop="dataTotal" label="数据条数">
        </el-table-column>
        <el-table-column label="导出时间" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.createTime }}</template>
        </el-table-column>
        <el-table-column prop="createUser" label="操作人员">
        </el-table-column>
        <el-table-column prop="status" label="导出状态" width="150">
          <template slot-scope="scope">
            <div class="flex-c-c" v-if="scope.row.status==0">
              <div class="b-gary m-r-8 point"></div>
              <span class="inline-block gary">正在加载</span>
            </div>
            <div class="flex-c-c" v-if="scope.row.status==1">
              <div class="b-green m-r-8 point"></div>
              <span class="inline-block green">加载完成</span>
            </div>
            <div class="flex-c-c" v-if="scope.row.status==2">
              <div class="b-red m-r-8 point"></div>
              <span class="inline-block red">加载失败</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="更多">
          <template slot-scope="scope">
            <div class="work-cell">
              <el-link :underline="false" type="danger" :disabled='scope.row.status==0' @click="addDelId(scope.row)">删除</el-link>
              <div class="jiange"></div>
              <el-link :underline="false" type="primary" :disabled='scope.row.status!=1' @click="downloadFile(scope.row)">下载</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <Pagination :pageNumber="page.pageNumber" :pageSize="page.pageSize" :total="total" @SizeChange="SizeChange" @CurrentChange="CurrentChange" />
    </el-card>
  </div>
</template>

<script>
import Pagination from "@/components/pagination";
import api from "../../api/download/index";
export default {
  components: {
    Pagination
  },
  data() {
    return {
      name: '',
      type: '',
      typeList: [
        { value: 54, label: '有害信息' },
        { value: 55, label: '专项行动' },
        { value: 56, label: '信息监测' },
        { value: 57, label: '错误表述' }
      ],
      status: '',
      statusList: [
        { value: 0, label: '正在加载' },
        { value: 1, label: '加载完成' },
        { value: 2, label: '加载失败' }
      ],
      time: [],
      page: {
        page: 1,
        limit: 10
      },
      total: 10,
      tableData: [],
      multipleSelection: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    downloadFile(row) {
      if (row.status == 1) {
        this.$message.success('开始下载')
        const fileUrl = row.filePath;
        // 创建一个隐藏的链接元素
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = row.fileName;
        // 触发链接点击事件
        link.dispatchEvent(new MouseEvent('click'));
      } else {
        this.$message.warning('此数据暂不可下载')
      }
    },
    // 删除数据
    async delList() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请选择需要删除的数据')
      }
      let data = this.multipleSelection.map(v => Number(v.id))
      // console.log(data)
      let res = await api.delList(data)
      if (res.data.code == 200) {
        this.$message.success('删除成功')
        this.getList()
      }
    },
    // 单选
    addDelId(row) {
      this.multipleSelection = []
      this.multipleSelection.push(row)
      this.delList()
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },
    //重置
    reset() {
      this.page.page = 1
      this.name = ''
      this.type = ''
      this.status = ''
      this.time= []
      this.getList()
    },
    // 获取列表
    async getList() {
      let { name, type, status, time, page } = this
      let data = {
        page: page.page,
        limit: page.limit,
        name: name,
        moduleType: type,
        startCreateTime: time ? time[0] : undefined,
        endCreateTime: time ? time[1] : undefined,
        status
      }
      let res = await api.getList(data)
      if (res.data.code == 200) {
        this.tableData = res.data.data.records
        this.total = res.data.data.total * 1
      }
    },
    // 分页
    SizeChange(val) {
      this.page.limit = val;
      this.page.page = 1;
      this.getList()
    },
    CurrentChange(val) {
      this.page.page = val;
      this.getList()
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  .search {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 68px;
    background: #ffffff;
    border-radius: 4px;
    padding: 16px 24px;
    .el-input {
      width: 240px;
    }
    .el-select {
      width: 190px;
    }
    .search-btn {
      position: absolute;
      top: 16px;
      right: 24px;
      display: flex;
      justify-content: start;
      align-items: center;
      .query {
        width: 60px;
        height: 36px;
        background: #f58030;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
      }
      .reset {
        width: 60px;
        height: 36px;
        background: #ffffff;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 36px;
        text-align: center;
        border: 1px solid #e1e1e1;
        cursor: pointer;
      }
    }
  }
  .card {
    .batch-del {
      width: 88px;
      height: 36px;
      background: #fff1e8;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 500;
      color: #f58030;
      line-height: 36px;
      text-align: center;
      float: right;
      cursor: pointer;
    }
  }
}
//表格内容颜色

::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #f6f7fa;
}
</style>